import React, { useState, useEffect } from "react";
import SignUpRight from "../SignUpRight";
import LoginLeft from "../LoginLeft";
import { Container } from "../StyledComponents";
import { Div } from "../Styles";
import { LogoContainer, Vozoiconpic } from "../../LeftNav/Styles";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";
const SignUp = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "100vh" : "170vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          {" "}
          <Div width="50%" bg_color="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" bg_color="#FFFFFF" overflowY="auto">
            <SignUpRight />
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          <LogoContainer
            marginTop="0px"
            marginBottom="20px"
            marginRight="auto"
            marginLeft="auto"
          >
            <Vozoiconpic />
          </LogoContainer>
          <Div bg_color="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div bg_color="#FFFFFF" overflowY="auto">
            <SignUpRight />
          </Div>
        </Div>
      )}
    </Container>
  );
};

export default SignUp;
