import styled from "styled-components";
import { Image } from "../../StyledComponents";

export const TxtSpan = styled.span`
  color: ${(p) => (p.color ? p.color : "#000000")};
  font-size: 14px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
`;
export const PaymentStatus = styled(TxtSpan)`
  width: 41px;
  color: #20c9ac;
  background: rgba(32, 201, 172, 0.1);
  border-radius: 4px;
  padding: 2px 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 60px;
    background: #20824126;
    border-radius: 25px;
  }
`;
export const AvatarTxt = styled.span`
  font-size: 16px;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;
export const AvatarFrame = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #a8aebe;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 480px) {
    border-radius: 8px;
    height: 40px;
    width: 40px;
  }
`;
export const Container = styled.div`
  position: relative;
  border-top: 1px solid #e4e4e4;
  margin-top: 13px;
  margin-left: -40px;
  padding-top: 3px;
  margin: ${(p) => p.margin && p.margin};
  min-width: ${(p) => p.minWidth && p.minWidth};
  @media(max-width:480px){
  border-top: none;
  min-width:unset;
  margin-left:0px;

   &.marLeft {
      margin-right: 16px;
    }
  }
 
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #a8aebe;
  display: inline-block;

  margin-right: ${(p) => (p.marginRight ? p.marginRight : "12px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarLetter = styled.span`
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export const AvatarImage = styled(Image)`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 0px;
  }
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;
