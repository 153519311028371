import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetPassRes } from "../../../StateManagement/Reducers/PreLoginState";
import getFilePath from "../../../Config/path";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
} from "../../StyledComponents";
import {
  Title,
  Container,
  Input,
  TitleImage,
  ImageContainer,
} from "../StyledComponents";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import password_icon from "../../../Assets/images/password_setup_icon.png";
import {
  getCredentials,
  LoginPass,
} from "../../../StateManagement/Reducers/LoginState";
import { LogoContainer, Vozoiconpic } from "../../LeftNav/Styles";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";

const SetupPassword = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const Navigate = useNavigate();

  const [email] = useState(state ? state.type.email : "");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [btndis, setbtndis] = useState(false);
  const [open, setOpen] = useState(false);

  const File_URL = getFilePath();

  const text = useSelector((state) => state.PreLogin.logo);
  const passRes = useSelector((state) => state.PreLogin.passWordRes);
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const HandleSave = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e !== "skip" && e.preventDefault();

    if (passErr || misMatch) return;

    if (pwd1 === "") {
      setPassErr(true);
      setErrMsg("Please enter the password");
    }

    if (pwd2 === "") {
      setMissMatch(true);
      setErrMsg("Please enter the password");
    }
    if (pwd1 === pwd2) {
      dispatch(
        getCredentials({
          UserName: email,
          Password: pwd1,
          source: "forgot_password",
        })
      );

      if (
        Object.keys(LoginResponse).length > 0 &&
        LoginResponse["status"] &&
        LoginResponse["status"] !== ""
      ) {
        if (LoginResponse.status === "LOGIN_SUCCESS") {
          setMissMatch(true);

          setErrMsg("Enter different password");
        } else if (LoginResponse.status === "LOGIN_FAILURE") {
          dispatch(
            GetPassRes({
              type: { new_password: pwd1, con_password: pwd2, email: email },
            })
          );

          dispatch(LoginPass({ status: "", output: "" }));
          Navigate("/Login");
        }
      }
    } else {
      setMissMatch(true);
      setErrMsg("Password mismatch");
    }
  };

  const PasswordCheck = (e) => {
    setMissMatch(false);
    setPwd1(e.target.value);

    if (
      Object.keys(LoginResponse).length > 0 &&
      LoginResponse["status"] &&
      LoginResponse["status"] !== ""
    ) {
      dispatch(LoginPass({ status: "", output: "" }));
    }
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformPasswordCheck = (e) => {
    setMissMatch(false);
    if (
      Object.keys(LoginResponse).length > 0 &&
      LoginResponse["status"] &&
      LoginResponse["status"] !== ""
    ) {
      dispatch(LoginPass({ status: "", output: "" }));
    }
    setPwd2(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
    } else {
      setMissMatch(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  useEffect(() => {
    if (passRes === "Success") {
      Navigate("/Login");
    } else if (passRes === "Failure") {
      setMissMatch(true);
      setErrMsg("passWord saving failed");
    }
  }, [passRes]);

  useEffect(() => {
    if (Object.keys(LoginResponse).length > 0) {
      HandleSave("skip");
    }
  }, [LoginResponse]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "100vh" : "250vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          <Div width="50%" backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" backColor="#FFFFFF">
            <Div width="100%">
              <Div width="500px" textAlign="center" margin="auto">
                {text.length !== 0 && (
                  <ImageContainer marginTop="72px" marginBottom="20px">
                    <Image
                      loading="lazy"
                      src={File_URL + text}
                      height="41px"
                      width="173px"
                    />
                  </ImageContainer>
                )}
                <TitleImage
                  src={password_icon}
                  alt="Setup password"
                  width="124px"
                  height="124px"
                  marginTop="3%"
                ></TitleImage>
                <Title
                  color="#000000"
                  fontWeight="700"
                  fontSize="36px"
                  lineHeight="44px"
                  marginBottom="12px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Reset Your Password
                </Title>
                <FormGroup
                  marginBottom="32px"
                  display="grid"
                  width="100%"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Your ID has been verified! Set your new password here
                </FormGroup>
                <Form>
                  <FormGroup marginBottom="19px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="6px"
                      textAlign="left"
                      color="#151515"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="24px"
                      letterSpacing="1px"
                      fontFamily="'DM Sans', sans-serif"
                    >
                      Password
                    </FormLabel>
                    <Input
                      borderRadius="100px"
                      fontFamily="'DM Sans', sans-serif"
                      placeholder="Enter password"
                      autoComplete="off"
                      type={PasswordShow ? "password" : "text"}
                      value={pwd1}
                      onChange={(e) => PasswordCheck(e)}
                    />
                    {PasswordShow ? (
                      <FaRegEye
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "43px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow((Prevstate) => !Prevstate);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "43px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow((Prevstate) => !Prevstate);
                        }}
                      />
                    )}
                    {passErr && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        fontFamily="inter"
                        lineHeight="24px"
                        marginTop="5px"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>
                  <FormGroup marginBottom="19px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="6px"
                      textAlign="left"
                      color="#151515"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="24px"
                      letterSpacing="1px"
                      fontFamily="'DM Sans', sans-serif"
                    >
                      Confirm Password
                    </FormLabel>
                    <Input
                      borderRadius="100px"
                      fontFamily="'DM Sans', sans-serif"
                      placeholder="Re-enter password"
                      autoComplete="off"
                      type={PasswordShow1 ? "password" : "text"}
                      value={pwd2}
                      onChange={(e) => ConformPasswordCheck(e)}
                    />
                    {PasswordShow1 ? (
                      <FaRegEye
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "43px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow1((Prevstate) => !Prevstate);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "43px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow1((Prevstate) => !Prevstate);
                        }}
                      />
                    )}
                    {misMatch && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        fontFamily="inter"
                        lineHeight="24px"
                        marginTop="5px"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>

                  <FormGroup marginBottom="32px" display="grid" width="100%">
                    <Button
                      borderRadius="100px"
                      background="#245C66"
                      hoverBackColor="#16424A"
                      height="48px"
                      disabled={btndis}
                      onClick={(e) => HandleSave(e)}
                    >
                      Save password
                    </Button>
                  </FormGroup>
                </Form>
              </Div>
            </Div>
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          <LogoContainer
            marginTop="0px"
            marginBottom="20px"
            marginRight="auto"
            marginLeft="auto"
          >
            <Vozoiconpic />
          </LogoContainer>
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div backColor="#FFFFFF">
            <Div width="100%">
              <Div width="96%" textAlign="center" margin="auto">
                {text.length !== 0 && open && (
                  <ImageContainer marginTop="72px" marginBottom="65px">
                    <Image
                      loading="lazy"
                      src={File_URL + text}
                      height="41px"
                      width="173px"
                    />
                  </ImageContainer>
                )}
                <TitleImage
                  src={password_icon}
                  alt="Setup password"
                  width="100px"
                  height="80px"
                  marginTop="7%"
                  marginBottom="0%"
                ></TitleImage>
                <Title
                  fontFamily="'DM Sans', sans-serif"
                  color="#000000"
                  fontWeight="700"
                  fontSize="24px"
                  lineHeight="44px"
                  marginBottom="12px"
                >
                  Reset Your Password
                </Title>
                <FormGroup
                  marginBottom="16px"
                  fontFamily="'DM Sans', sans-serif"
                  fontSize="14px"
                  display="grid"
                  width="100%"
                >
                  Your ID has been verified! Set your new password here
                </FormGroup>
                <Form
                  padding={open ? "" : "0 10px 0 15px"}
                  fontFamily="'DM Sans', sans-serif"
                >
                  <FormGroup marginBottom="16px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="4px"
                      textAlign="left"
                      color={open ? "#151515" : "#151515"}
                      fontWeight={open ? "600" : "400"}
                      fontSize="14px"
                      lineHeight="24px"
                      letterSpacing="1px"
                    >
                      Password
                    </FormLabel>
                    <Input
                      height="40px"
                      borderRadius="100px"
                      placeholder="Enter password"
                      autoComplete="off"
                      type={PasswordShow ? "password" : "text"}
                      value={pwd1}
                      onChange={(e) => PasswordCheck(e)}
                    />
                    {PasswordShow ? (
                      <FaRegEye
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "40px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow((Prevstate) => !Prevstate);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "40px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow((Prevstate) => !Prevstate);
                        }}
                      />
                    )}
                    {passErr && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        fontFamily="inter"
                        lineHeight="24px"
                        marginTop="5px"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>
                  <FormGroup marginBottom="19px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="4px"
                      textAlign="left"
                      color={open ? "#151515" : "#151515"}
                      fontWeight={open ? "600" : "400"}
                      fontSize="14px"
                      lineHeight="24px"
                      letterSpacing="1px"
                    >
                      Confirm Password
                    </FormLabel>
                    <Input
                      height="40px"
                      borderRadius="100px"
                      placeholder="Re-enter password"
                      autoComplete="off"
                      type={PasswordShow1 ? "password" : "text"}
                      value={pwd2}
                      onChange={(e) => ConformPasswordCheck(e)}
                    />
                    {PasswordShow1 ? (
                      <FaRegEye
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "40px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow1((Prevstate) => !Prevstate);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        style={{
                          position: "absolute",
                          right: 15,
                          top: "40px",
                          cursor: "pointer",
                          color: "#7d8592",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setPasswordShow1((Prevstate) => !Prevstate);
                        }}
                      />
                    )}
                    {misMatch && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        fontFamily="inter"
                        lineHeight="24px"
                        marginTop="5px"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>

                  <FormGroup marginBottom="32px" display="grid" width="100%">
                    <Button
                      fontFamily="'DM Sans', sans-serif"
                      borderRadius={open ? "8px" : "100px"}
                      background="#245C66"
                      hoverBackColor="#16424A"
                      height="40px"
                      disabled={btndis}
                      onClick={(e) => HandleSave(e)}
                    >
                      Save password
                    </Button>
                  </FormGroup>
                </Form>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
    </Container>
  );
};

export default SetupPassword;
