import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsXLg } from "react-icons/bs";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { toast } from "react-toastify";
import Error from "../Toastify/Error";

import {
  AddCardDetails,
  GetCardDetails,
} from "../../../StateManagement/Reducers/BillingState";

import {
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormLabel,
  Switch,
  Toggle,
  Slider,
  Button,
} from "../../StyledComponents";
import { Div } from "../../Billing/MyCards/Styles";
import {
  ModalDialog,
  Input,
  AdCardNum,
  ErrorMessage,
  CardWrapper,
} from "./Styles";
import { Elements } from "@stripe/react-stripe-js";

const AddCardData = (props) => {
  //------------------------------------------State and Variables Start--------------------------------------//
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [nameErr, setNameErr] = useState(false);
  const [noErr, setNoErr] = useState(false);
  const [cvvErr, setCvvErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [cardType, setCardType] = useState("");

  const [CardHolderName, setCardHolderName] = useState("");
  const [CardNumber, setCardNumber] = useState("");
  const [CVV, setCVV] = useState("");
  const [ExpDate, setExpDate] = useState("");
  const [DefaultCard, setDefaultCard] = useState(false);
  const [DefaultCardVal, setDefaultCardVal] = useState(0);
  const [ModalAnimat] = useState(true);

  const [btnDisable, setBtnDis] = useState(false);

  const Refresh = useSelector((state) => state.Billing.Refresh);
  const numcard = useSelector((state) => state.Billing.CardDetail).length;

  const inputStyle = {
    color: "black",
    fontWeight: "500",
    fontFamily: "Open Sans,sans-serif",
    fontSize: "14px",

    "::placeholder": {
      color: "rgb(113, 128, 150, 0.4)",
    },
  };

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//

  const HandleToggle = (e) => {
    if (e.target.checked === true) {
      setDefaultCard((Prevsate) => !Prevsate);
      setDefaultCardVal(1);
    } else {
      setDefaultCard((Prevsate) => !Prevsate);
      setDefaultCardVal(0);
    }
  };

  const HandleClose = () => {
    props.HandleModal();
    setNameErr(false);
    setNoErr(false);
    setCvvErr(false);
    setDateErr(false);
    setCardHolderName("");
    setCardNumber("");
    setCVV("");
    setExpDate("");
    setCardType("");
    setDefaultCardVal(false);
    setDefaultCardVal(0);
    setBtnDis(false);

    const cardElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    cardElement.clear();
    cardCvcElement.clear();
    cardExpiryElement.clear();
  };

  const cardNoChange = (cardno) => {
    if (cardno.brand) {
      setCardType(cardno.brand);
    } else {
      setCardType("none");
    }
  };

  const NameChange = (value) => {
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setCardHolderName(value);
      setNameErr(false);
    }
  };

  const HandleSave = async () => {
    if (!stripe || !elements) {
      return;
    }

    setBtnDis(true);
    var err = 0;

    if (CardHolderName === "") {
      setNameErr(true);
      err = 2;
    }

    if (dateErr) {
      err = 2;
    }

    if (err > 0) {
      setBtnDis(false);
      return false;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    const cardData = {
      name: CardHolderName,
    };

    await stripe
      .createToken(cardElement, cardData)
      .then(async (result) => {
        if (result.error && result.error.message) {
          toast(<Error msg={result.error.message} />, {
            containerId: "B",
            className: "error_badge",
          });
          setBtnDis(false);
        } else {
          await stripe
            .createPaymentMethod({
              type: "card",
              card: cardElement,
            })
            .then((res) => {
              if (res.error && res.error.message) {
                toast(<Error msg={res.error.message} />, {
                  containerId: "B",
                  className: "error_badge",
                });
                setBtnDis(false);
              } else {
                let article = {
                  CardHolderName,
                  CardNumber,
                  CVV,
                  DefaultCardVal,
                  token: result.token.id,
                  paymentMethod: res.paymentMethod.id,
                  month: res.paymentMethod.card.exp_month,
                  year: res.paymentMethod.card.exp_year,
                };

                if (result.error && result.error.message) {
                  //how error message
                } else {
                  dispatch(AddCardDetails(article));
                  cardElement.clear();
                  cardCvcElement.clear();
                  cardExpiryElement.clear();
                }
              }
            })
            .catch((err) => {
              console.error(err.message);
            });
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  //------------------------------------------Functions End--------------------------------------------------//

  useEffect(() => {
    if (Refresh !== "") {
      props.HandleModal();
      dispatch(GetCardDetails());
      setBtnDis(false);
      setCardHolderName("");
      setCardNumber("");
      setCVV("");
      setExpDate("");
      setCardType("");
      setDefaultCardVal(false);
      setDefaultCardVal(0);
    }
    if (numcard === 0) {
      setDefaultCard(true);
      setDefaultCardVal(1);
    } else {
      setDefaultCard(false);
      setDefaultCardVal(0);
    }
  }, [dispatch, Refresh, numcard]);

  return (
    <Modal show={props.show} height="100%" width="100%">
      <ModalContainer justifyContent="center">
        <ModalDialog
          animation={ModalAnimat}
          className="Addcard_container_responsive"
        >
          <ModalHeader
            padding="30px 40px"
            borderLess
            justifyContent="space-between"
          >
            <ModalTitle
              lineHight="30px"
              fontSize="22px"
              fontWeight="600"
              color="#0A1629"
            >
              Add a Card
            </ModalTitle>
            <BsXLg
              style={{ color: "#407BFF", cursor: "pointer", size: "16px" }}
              onClick={() => HandleClose()}
            />
          </ModalHeader>

          <ModalBody padding="0px 40px 24px 40px">
            <Form>
              <FormGroup
                display="flex"
                flexDirection="column"
                marginBottom="12px"
              >
                <FormLabel
                  color="#7D8592"
                  lineHight="24px"
                  fontSize="14px"
                  fontWeight="500"
                  marginBottom="8px"
                >
                  Card Holder Name
                </FormLabel>
                <Input
                  value={CardHolderName}
                  style={
                    nameErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  onChange={(e) => NameChange(e.target.value)}
                />
                {nameErr && <ErrorMessage>Enter a valid name</ErrorMessage>}
              </FormGroup>

              <FormGroup
                display="flex"
                flexDirection="column"
                marginBottom="12px"
              >
                <FormLabel
                  color="#7D8592"
                  lineHight="24px"
                  fontSize="14px"
                  fontWeight="500"
                  marginBottom="8px"
                >
                  Card Number
                </FormLabel>

                <CardWrapper
                  border="1px solid rgb(216, 224, 240)"
                  borderRadius="6px"
                  padding="16px 12px"
                  background="rgb(255, 255, 255)"
                  marginTop="8px"
                  height="50px"
                  //width="469px"
                  cardbrand={cardType}
                  className="addcard_cardno_responsive"
                >
                  <CardNumberElement
                    className="sub-card-width"
                    options={{
                      style: {
                        base: inputStyle,
                      },
                    }}
                    onChange={(e) => {
                      cardNoChange(e);
                    }}
                  />
                </CardWrapper>
              </FormGroup>

              <FormGroup
                display="flex"
                justifyContent="space-between"
                marginBottom="24px"
              >
                <FormGroup display="flex" flexDirection="column" width="47.5%">
                  <FormLabel
                    color="#7D8592"
                    lineHight="24px"
                    fontSize="14px"
                    fontWeight="500"
                    marginBottom="8px"
                  >
                    CVV
                  </FormLabel>

                  <CardWrapper
                    border="1px solid rgb(216, 224, 240)"
                    borderRadius="6px"
                    padding="16px 12px"
                    background="rgb(255, 255, 255)"
                    marginTop="8px"
                    height="50px"
                    // width="223px"
                  >
                    <CardCvcElement
                      options={{
                        placeholder: "cvv",
                        style: {
                          base: {
                            "::placeholder": {
                              color: "rgb(113, 128, 150, 0.4)",
                            },
                          },
                        },
                      }}
                    />
                  </CardWrapper>
                </FormGroup>

                <FormGroup display="flex" flexDirection="column" width="47.5%">
                  <FormLabel
                    color="#7D8592"
                    lineHight="24px"
                    fontSize="14px"
                    fontWeight="500"
                    marginBottom="8px"
                  >
                    Expiration
                  </FormLabel>

                  <CardWrapper
                    border="1px solid rgb(216, 224, 240)"
                    borderRadius="6px"
                    padding="16px 12px"
                    background="rgb(255, 255, 255)"
                    marginTop="8px"
                    height="50px"
                    // width="223px"
                  >
                    <CardExpiryElement
                      options={{
                        style: {
                          base: {
                            "::placeholder": {
                              color: "rgb(113, 128, 150, 0.4)",
                            },
                          },
                        },
                      }}
                    />
                  </CardWrapper>
                </FormGroup>
              </FormGroup>

              <FormGroup display="flex" alignItems="center">
                <Switch>
                  <Toggle
                    checked={DefaultCard}
                    disabled={numcard === 0 ? true : false}
                    cursor={numcard === 0 ? "true" : "false"}
                    type="checkbox"
                    onClick={(e) => HandleToggle(e)}
                  />
                  <Slider />
                </Switch>
                <FormLabel
                  color="#7D8592"
                  lineHight="24px"
                  fontSize="14px"
                  fontWeight="500"
                  marginLeft="8px"
                >
                  Default Card
                </FormLabel>
              </FormGroup>
            </Form>
          </ModalBody>

          <ModalFooter padding="24px 40px">
            <Div display="flex" justify="flex-end" alignItems="center">
              <Button
                background="#2C7BE5"
                hoverBackColor="#005FB2"
                color=" #FFF"
                borderRadius="4px"
                padding="12px 16px"
                fontWeight="700"
                fontFamily="'Nunito Sans', sans-serif"
                boxShadow="0px 6px 12px rgba(46, 44, 52, 0.24)"
                onClick={() => HandleSave()}
                className="save_active"
                disabled={btnDisable}
                id="bil_ad-btn_crdSv"
              >
                Save Card
              </Button>
            </Div>
          </ModalFooter>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

const AddCard = (props) => {
  const publishableKey = useSelector((state) => state.Billing.stripePubKey);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      if (!publishableKey) {
        return null;
      }
      return await loadStripe(publishableKey);
    };

    initializeStripe().then((result) => setStripePromise(result));
  }, [publishableKey]);
  return (
    <Elements stripe={stripePromise}>
      <AddCardData {...props} />
    </Elements>
  );
};

export default AddCard;
