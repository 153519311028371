import React, { useState } from "react";
import { useSelector } from "react-redux";

import CardDetail from "../../StyledComponents/CardDetails";
import AddCard from "../../StyledComponents/AddCard";
import { PageTitleVariant } from "./Styles";
import { PageSubTitle, Image } from "../../StyledComponents";
import {
  Div,
  Card,
  Icon,
  CardGroup,
  CardContainer,
  CardNum,
  CardHoldTxt,
  HoldName,
  CardBrand,
  Curcle,
  InnerCurcle,
  Tooltip,
  TooltipText,
} from "./Styles";

import ChipPin from "../../../Assets/images/Group.png";
import StripeAlert from "../../StyledComponents/StripeAlert";

const MyCards = () => {
  const [AddShow, setAddShow] = useState(false);
  const [CardDetailShow, setCardDetailShow] = useState(false);
  const [CardData, setCardData] = useState({});

  const CardDetails = useSelector((state) => state.Billing.CardDetail);
  const [NumCard] = useState(CardDetails.length);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  const HandleModal = () => {
    if (stripeAllowed === false) {
      setModalAlerShow(true);
    } else {
      setAddShow((prevState) => !prevState);
    }
  };
  const HandleEdit = (ind) => {
    setCardDetailShow((prevState) => !prevState);
    setCardData(ind);
  };

  const handleModalClick = () => {
    setModalAlerShow(false);
  };
  return (
    <>
      <Div>
        <PageTitleVariant>Billing</PageTitleVariant>
        <PageSubTitle className="mobFontSize">My Cards</PageSubTitle>

        <CardGroup>
          {CardDetails.length !== 0 ? (
            <>
              {CardDetails.map((row, ind) => (
                <>
                  <CardContainer
                    className="mobBillingCardMar"
                    key={ind}
                    selected={ind}
                    onClick={() => HandleEdit(row)}
                  >
                    <Div
                      margin="0px 0px 27px 0px"
                      display="flex"
                      alignItems="center"
                    >
                      <Image loading="lazy" src={ChipPin} />
                    </Div>

                    <CardNum selected={ind}>{row.number}</CardNum>

                    <Div
                      display="flex"
                      margin="20px 0px 0px 0px"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Div justify="space-between" width="100px">
                        <CardHoldTxt selected={ind}>Card Holder</CardHoldTxt>
                        <HoldName
                          selected={ind}
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                        >
                          {row.name && row.name.length > 12 ? (
                            <Tooltip>
                              {row.name}
                              <TooltipText className="TooltipText">
                                {row.name}
                              </TooltipText>
                            </Tooltip>
                          ) : (
                            row.name
                          )}
                        </HoldName>
                      </Div>

                      <Div margin="0px 0px 0px 16px">
                        <CardHoldTxt selected={ind}>Valid</CardHoldTxt>
                        <HoldName selected={ind}>{row.valid}</HoldName>
                      </Div>

                      <CardBrand img={row.cardType} selected={ind} />
                    </Div>

                    <Curcle selected={ind} />
                    <InnerCurcle selected={ind} />
                  </CardContainer>
                </>
              ))}
              {CardDetails.length < 3 && (
                <Card onClick={() => HandleModal()}>
                  <Icon />
                  Add Card
                </Card>
              )}
            </>
          ) : (
            <Card onClick={() => HandleModal()}>
              <Icon />
              Add Card
            </Card>
          )}
        </CardGroup>
      </Div>

      <AddCard
        show={AddShow}
        HandleModal={() => setAddShow(false)}
        numcard={NumCard}
      />

      <CardDetail
        show={CardDetailShow}
        HandleClose={() => setCardDetailShow(false)}
        row={CardData}
      />
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};

export default MyCards;
