import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Notifications from "./Notification";
import {
  GetUserDetails,
  GetNotificationDetails,
  GetNotificationUpdate,
} from "../../StateManagement/Reducers/TopNavbarState";
import { GetMsgList } from "../../StateManagement/Reducers/MessengerState";
import { Link, useLocation } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import {
  NormalImage,
  LinK,
  TextContent,
  Description,
  Div,
} from "../StyledComponents";
import {
  MessageContainer,
  ProfileBox,
  RedNotify,
  TopNavPlacer,
  DropdownContent,
  DropdownContentNotifi,
  DropdownList,
  FixedTop,
  StyledLink,
  PageTitleVariant,
  Header,
  Title,
  TextWrap,
} from "./Styles";
import getFilePath from "../../Config/path";

import { GrDown } from "react-icons/gr";
import { FiLogOut } from "react-icons/fi";
import { RiProfileLine } from "react-icons/ri";
import SendIcon from "../../Assets/images/Shape.png";
import NotifyIcon from "../../Assets/images/portal_notification.png";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { GetLoginStatus } from "../../StateManagement/Reducers/PreLoginState";
import { SetResetPassword } from "../../StateManagement/Reducers/SettingState";
import { LogoContainer, Vozoiconpic } from "../LeftNav/Styles";

const TopNav = ({ title, TitleDescription, settingmenu }) => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const inputNotRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;

  const [drop, setDrop] = useState(false);
  const [dropNotifi, setDropNotifi] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [topFixed, setTopFixed] = useState(false);
  const [unreadIS, setUnreadIS] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const PatName = useSelector((state) => state.TopNavbar.userDetails.patname);
  const img_url = useSelector((state) => state.TopNavbar.userDetails.img_url);
  const initials = useSelector((state) => state.TopNavbar.userDetails.initials);
  const PatientAccess = useSelector(
    (state) => state.TopNavbar.userDetails.allow_patient_portal
  );
  const Refresh = useSelector((state) => state.Setting.Refresh);
  const userList = useSelector((state) => state.Messenger.ProviderList);
  let accountType =
    localStorage.getItem("account_type") &&
    Decrypt_Value(localStorage.getItem("account_type"), "vozoportal");

  let accountUpdate =
    localStorage.getItem("account_update") &&
    Decrypt_Value(localStorage.getItem("account_update"), "vozoportal");

  const NotificationFullData = useSelector(
    (state) => state.TopNavbar.NotificationDetails
  );

  const DualColorPages = ["Appointment List", "Messenger"];

  const FileURL = getFilePath();

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //
  const notificationOpen = (e) => {
    if (accountType !== -1) {
      setDropNotifi(!dropNotifi);
      dispatch(GetNotificationUpdate(NotificationFullData));
    }
  };
  const closepopup = () => {
    setDropNotifi(false);
  };
  const Logout = () => {
    localStorage.clear();
    dispatch(GetLoginStatus({ pid: Pat_ID, uid: 0, type: "logout" }));
    window.location.href = "/";
  };

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 50 && title !== "Appointment") {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setDrop(false);
    }
  };
  const handleClick1 = (e) => {
    if (inputNotRef.current && !inputNotRef.current.contains(e.target)) {
      setDropNotifi(false);
    }
  };

  const backStyle = {
    fontSize: "18px",
    marginRight: "10px",
    cursor: "pointer",
  };

  const backFunc = () => {
    window.history.back();
    dispatch(SetResetPassword(""));
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start------------------------------------------------------------- //

  useEffect(() => {
    if (PatientAccess !== undefined && PatientAccess !== "YES") {
      localStorage.clear();
      dispatch(GetLoginStatus({ pid: Pat_ID, uid: 0, type: "logout" }));
      window.location.href = "/Login";
    }
  }, [PatientAccess]);

  useEffect(() => {
    if (NotificationFullData !== null && NotificationFullData !== undefined) {
      const checkNotification = NotificationFullData.filter(
        (pat) => pat.portal_notifi_rem.toString() === "0"
      );

      setUnreadNotification(checkNotification.length > 0 ? true : false);
    } else {
      setUnreadNotification(false);
    }
  }, [NotificationFullData]);

  useEffect(() => {
    dispatch(GetUserDetails());

    if (accountUpdate === 2) {
      localStorage.clear();
      dispatch(GetLoginStatus({ pid: Pat_ID, uid: 0, type: "logout" }));
      localStorage.setItem("account_update", Encrypt_Value(2, "vozoportal"));
      window.location.href = "/Login";
    }
  }, [dispatch, Refresh, pathname]);

  useEffect(() => {
    dispatch(GetMsgList());
    dispatch(GetNotificationDetails());
  }, [dispatch, Refresh]);

  useEffect(() => {
    dispatch(GetNotificationDetails());
  }, [dispatch, dropNotifi]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mouseup", handleClick);
    document.addEventListener("mouseup", handleClick1);
    if (DualColorPages.includes(title) && open === true) {
      setTopFixed(true);
    } else {
      setTopFixed(false);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mouseup", handleClick);
      document.addEventListener("mouseup", handleClick1);
    };
  }, [open]);
  useEffect(() => {
    for (let i = 0; i < userList.length; i++) {
      if (userList[i]["unreadCount"] !== "0") {
        setUnreadIS(true);
        break;
      } else {
        setUnreadIS(false);
      }
    }

    return () => {};
  }, [userList]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <FixedTop
      scrolled={scrolled}
      DualColor={topFixed}
      topTitle={title}
      className="topnav"
    >
      {open ? (
        <TextContent alignItems="left">
          {title === "Change Password" ? (
            <Div display="flex" alignItems="center" textAlign="center">
              <RiArrowLeftLine size="26" style={backStyle} onClick={backFunc} />
              <PageTitleVariant>{title}</PageTitleVariant>
            </Div>
          ) : (
            <PageTitleVariant>{title}</PageTitleVariant>
          )}
          {(title === "Appointment List" || title === "Messenger") && (
            <Description
              fontWeight="400"
              fontSize="14px"
              lineHeight="19px"
              color="#7d8592"
              align="left"
              margin="8px auto 0px 0px"
            >
              {TitleDescription}
            </Description>
          )}
        </TextContent>
      ) : (
        <LogoContainer
          marginTop="0px"
          marginBottom="0px"
          marginLeft="auto"
          marginRight="auto"
        >
          <Vozoiconpic className="topnavicon" />
        </LogoContainer>
      )}

      <TopNavPlacer>
        <Div
          width="40px"
          borderRadius="50%"
          marginRight="16px"
          ref={inputNotRef}
        >
          {accountType !== -1 && (
            <MessageContainer onClick={notificationOpen} DualColor={topFixed}>
              <NormalImage loading="lazy" src={NotifyIcon} id="tp_nv-pst_ico" />
              {unreadNotification && <RedNotify />}
            </MessageContainer>
          )}
          <DropdownContentNotifi
            top={DualColorPages.includes(title) ? "84px" : "70px"}
            display={dropNotifi.toString()}
          >
            <Notifications
              closepopup={closepopup}
              NotificationFullData={NotificationFullData}
            />
          </DropdownContentNotifi>
        </Div>
        {![1, -1].includes(accountType) && (
          <StyledLink to="/messenger" exact="true">
            <MessageContainer DualColor={topFixed}>
              <NormalImage loading="lazy" src={SendIcon} id="tp_nv-pst_ico" />
              {unreadIS && <RedNotify />}
            </MessageContainer>
          </StyledLink>
        )}
        <ProfileBox
          ref={inputRef}
          onClick={() => setDrop(!drop)}
          DualColor={topFixed}
          padding={open ? "0px 16px 0px 8px" : "0px"}
        >
          {open ? (
            <>
              {img_url && img_url !== null ? (
                <NormalImage
                  loading="lazy"
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  src={FileURL + img_url}
                />
              ) : (
                <Div
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  background="#A8AEBE"
                  color="white"
                  flexCenterAll
                  margin="0 8px 0 0"
                  fontSize="16px"
                  fontFamily="'Work Sans',sans-serif"
                  textShadow="0px 1px 0px rgba(0, 0, 0, 0.15)"
                >
                  {initials}
                </Div>
              )}
              <TextWrap>{PatName}</TextWrap>

              <GrDown />
            </>
          ) : (
            <>
              {img_url && img_url !== null ? (
                <NormalImage
                  loading="lazy"
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  src={FileURL + img_url}
                />
              ) : (
                <Div
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  background="#A8AEBE"
                  color="white"
                  flexCenterAll
                  margin="0 8px 0 0"
                  fontSize="16px"
                  fontFamily="'Work Sans',sans-serif"
                  textShadow="0px 1px 0px rgba(0, 0, 0, 0.15)"
                >
                  {initials}
                </Div>
              )}
              {/* <GrDown /> */}
            </>
          )}
          <DropdownContent
            width={open ? "98%" : "auto"}
            left={open ? "1%" : "-102%"}
            display={drop.toString()}
          >
            <LinK to="/profile/editProfile">
              <DropdownList id="tpNav_prf-clk_mPrf">
                <RiProfileLine />
                My Profile
              </DropdownList>
            </LinK>
            <DropdownList red onClick={Logout}>
              <FiLogOut />
              Logout
            </DropdownList>
          </DropdownContent>
        </ProfileBox>
      </TopNavPlacer>
    </FixedTop>
  );
};

export default TopNav;
