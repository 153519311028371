import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  EditAppointment,
  changeAppointmentData,
  clearAppointmentData,
  getOnsiteAppReq,
} from "../../../StateManagement/Reducers/AppointmentsState";

import { toast } from "react-toastify";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  Div,
  Description,
  Image,
  Button,
  FlexJustCenter,
  Span,
  PageSubTitle,
} from "../../StyledComponents";
import {
  Container,
  CardContainer,
  DateContainer,
  Options,
  CardBody,
  List,
  BackgroundDiv,
  Address,
  CheckinButton,
  Div1,
  Status,
  RespSubTitle,
  RespDiv,
  Ptag,
} from "./styles";

import { AiFillClockCircle, AiOutlinePlus } from "react-icons/ai";
import {
  BiUserCircle,
  BiLocationPlus,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { RiEditLine, RiDeleteBin7Line } from "react-icons/ri";
import { HiOutlineVideoCamera } from "react-icons/hi";
import AppointmentCancel from "../../Appointment/AppointmentCancel";
import NoAppointmentImage from "../../../Assets/images/no_appt_image.png";
import cardBackground from "../../../Assets/images/card_background.png";

const AppointmentsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [cardOpenId, setCardOpenId] = useState([]);
  const [cancelAppt, setCancelAppt] = useState(false);
  const [mobileview, setmobileview] = useState(false);

  const AppointmentLists = useSelector(
    (state) => state.Appointments.upcomingAppointment
  );
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const OnsiteAppReq = useSelector((state) => state.Appointments.OnsiteAppReq);

  const setOpenButton = (value) => {
    if (cardOpenId === value) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
    setCardOpenId(value);
  };

  const StartVideo = (eventTime, start_url) => {
    let currentDateTime = new Date();
    let eventDateTime = new Date(eventTime);
    if (currentDateTime < eventDateTime) {
      alert(`Your Meeting is scheduled at ${eventTime}`);
      return false;
    }
    window.open(start_url, "_blank");
  };

  const handleClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const StartEditAppointment = (data) => {
    dispatch(EditAppointment(data));
    navigate("/appointment/add_appointment");
    setOpen(false);
  };

  const scheduleapp = () => {
    if (OnsiteAppReq["allow_portal"] === "1") {
      if (OnsiteAppReq["individual_patient"] === "0") {
        navigate("/appointment/add_appointment");
        dispatch(clearAppointmentData(""));
      } else if (
        OnsiteAppReq["individual_patient"] === "1" &&
        OnsiteAppReq["newpat_allow"] === "1"
      ) {
        navigate("/appointment/add_appointment");
        dispatch(clearAppointmentData(""));
      } else {
        toast(<Error msg="You're not allow to Make an Appointment" />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    } else {
      toast(<Error msg="You're not allow to Make an Appointment......" />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    dispatch(getOnsiteAppReq());
    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;

      const isMobile = currentWidth >= 320 && currentWidth <= 840;

      isMobile ? setmobileview(true) : setmobileview(false);
    };
    // Initialize on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <RespDiv
      className="Upcoming_responsive"
      width="100%"
      height="100%"
      backColor="#FFFFFF"
      padding="13px 0px 0px 40px"
    >
      {!mobileview ? (
        <>
          <RespSubTitle
            fontWeight="500"
            fontSize="20px"
            lineHeight="25px"
            color="#000000"
          >
            Upcoming Appointments
          </RespSubTitle>
          <Description
            fontWeight="400"
            fontSize="14px"
            lineHeight="19px"
            color="#7d8592"
            margin="9px 0px 0px 0px"
          >
            Manage Your Appointment
          </Description>
          {AppointmentLists.length === 0 ? (
            <>
              <Image
                loading="lazy"
                src={NoAppointmentImage}
                width="68%"
                height="24.5%"
                margin="30px auto 40px"
                display="block"
              />

              <Button
                border="1px solid #2C7BE5"
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                borderRadius="4px"
                background="#ffffff"
                color="#2C7BE5"
                padding="12px 20px"
                fontSize="12px"
                lineHeight="18px"
                fontWeight="600"
                width="calc(100% - 36px)"
                className="close_active"
                onClick={scheduleapp}
              >
                <AiOutlinePlus
                  viewBox="150 -60 900 900"
                  width="12"
                  height="12"
                  margin="0 10px"
                />
                Schedule Appointment
              </Button>
            </>
          ) : (
            <Container open={open}>
              {AppointmentLists.length > 0 && (
                <>
                  {AppointmentLists.map((lists, index) => (
                    <CardContainer
                      accept={lists.accept}
                      index={index}
                      background={`url(${cardBackground})`}
                    >
                      <FlexJustCenter>
                        <DateContainer index={index} accept={lists.accept}>
                          {lists.pc_eventDate
                            ? moment(
                                lists.pc_eventDate,
                                "dddd DD MMM YYYY"
                              ).format("dddd MM/DD/YYYY")
                            : ""}
                        </DateContainer>
                        {lists.accept !== "2" && (
                          <Options
                            index={index}
                            accept={lists.accept}
                            onClick={(e) => {
                              setOpenButton(lists.pc_eid);
                            }}
                          >
                            <BiDotsVerticalRounded />
                          </Options>
                        )}
                      </FlexJustCenter>
                      {cardOpenId === lists.pc_eid && (
                        <Div1 ref={inputRef} display={open} right="-20px">
                          <Button
                            color="#414141"
                            display="block"
                            family="'Inter'"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            padding="8px 52px 8px 16px"
                            background="#ffffff"
                            textAlign="left"
                            width="100%"
                            hoverBackColor="#FAFAFA"
                            disabled={lists.accept === "2"}
                            onClick={() => StartEditAppointment(lists)}
                          >
                            <RiEditLine marginRight="14px" />
                            <Span marginLeft="14px">Edit</Span>
                          </Button>
                          <Button
                            color="#414141"
                            display="flex"
                            family="'Inter'"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            padding="8px 52px 8px 16px"
                            background="#ffffff"
                            hoverBackColor="#FAFAFA"
                            width="100%"
                            disabled={
                              appointmentData["eid"] === lists.pc_eid ||
                              lists.accept === "2"
                            }
                            onClick={() => {
                              setCancelAppt(true);
                              dispatch(
                                changeAppointmentData({
                                  name: "cancelID",
                                  value: lists.pc_eid,
                                })
                              );
                              setOpen(false);
                            }}
                          >
                            <RiDeleteBin7Line marginRight="14px" />
                            <Span marginLeft="14px">Cancel</Span>
                          </Button>
                        </Div1>
                      )}
                      <CardBody index={index} accept={lists.accept}>
                        <List>
                          <BackgroundDiv index={index} accept={lists.accept}>
                            <BiUserCircle />
                          </BackgroundDiv>
                          {lists.provider_name}
                        </List>
                        <List display="flex">
                          <BackgroundDiv index={index} accept={lists.accept}>
                            <AiFillClockCircle />
                          </BackgroundDiv>
                          <Ptag>{lists.duration}</Ptag>
                        </List>
                        <List>
                          <div>
                            {lists.tele_health === "1" ? (
                              <>
                                <BackgroundDiv
                                  index={index}
                                  accept={lists.accept}
                                >
                                  <BiLocationPlus />
                                </BackgroundDiv>
                                "Tele Health"
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: "17px",
                                  }}
                                >
                                  <BackgroundDiv
                                    index={index}
                                    accept={lists.accept}
                                  >
                                    <BiLocationPlus />
                                  </BackgroundDiv>
                                  <p
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      marginTop: "14px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    {lists.facility_name}
                                  </p>
                                </div>
                                <Address index={index} accept={lists.accept}>
                                  {lists.street},{lists.city},{lists.state},
                                  {lists.postal_code}
                                </Address>
                              </>
                            )}
                          </div>
                        </List>
                      </CardBody>

                      {lists.accept === "0" && (
                        <Status accept={lists.accept}>
                          Appointment request{" "}
                        </Status>
                      )}

                      {lists.accept === "1" &&
                        lists.tele_health === "1" &&
                        index === 0 && (
                          <CheckinButton
                            index={index}
                            onClick={() =>
                              StartVideo(lists.eventDateTime, lists.start_url)
                            }
                          >
                            <BackgroundDiv index={index} tag="button">
                              <HiOutlineVideoCamera />
                            </BackgroundDiv>
                            <Span marginLeft="8px">Join Telehealth</Span>
                          </CheckinButton>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "1" &&
                        index > 0 && (
                          <Status accept={lists.accept}>
                            Join Telehealth{" "}
                          </Status>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "0" &&
                        index === 0 && (
                          <CheckinButton index={index}>
                            Appointment Confirmed
                          </CheckinButton>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "0" &&
                        index >= 1 && (
                          <Status accept={lists.accept}>
                            Appointment Confirmed{" "}
                          </Status>
                        )}

                      {lists.accept === "2" && (
                        <Status accept={lists.accept}>Cancelled </Status>
                      )}
                    </CardContainer>
                  ))}
                </>
              )}
            </Container>
          )}
        </>
      ) : (
        <>
          <Button
            border="1px solid #2C7BE5"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            borderRadius="100px"
            background="#2C7BE5"
            color="#ffffff"
            padding="12px 20px"
            fontSize="12px"
            lineHeight="18px"
            fontWeight="600"
            width="100%"
            marginBottom="16px"
            className="close_active"
            onClick={scheduleapp}
          >
            Schedule Appointment
          </Button>
          <RespSubTitle
            fontWeight="500"
            fontSize="20px"
            lineHeight="25px"
            color="#000000"
          >
            Upcoming Appointments
          </RespSubTitle>
          <Description
            fontWeight="400"
            fontSize="14px"
            lineHeight="19px"
            color="#7d8592"
            margin="9px 0px 0px 0px"
          >
            Manage Your appointment
          </Description>
          {AppointmentLists.length === 0 ? (
            <>
              <Image
                loading="lazy"
                src={NoAppointmentImage}
                width="68%"
                height="24.5%"
                margin="30px auto 40px"
                display="block"
                className="image_responsive"
              />
            </>
          ) : (
            <Container open={open}>
              {AppointmentLists.length > 0 && (
                <>
                  {AppointmentLists.map((lists, index) => (
                    <CardContainer
                      accept={lists.accept}
                      index={index}
                      background={`url(${cardBackground})`}
                    >
                      <FlexJustCenter>
                        <DateContainer index={index} accept={lists.accept}>
                          {lists.pc_eventDate
                            ? moment(
                                lists.pc_eventDate,
                                "dddd DD MMM YYYY"
                              ).format("dddd MM/DD/YYYY")
                            : ""}
                        </DateContainer>
                        {lists.accept !== "2" && (
                          <Options
                            index={index}
                            accept={lists.accept}
                            onClick={(e) => {
                              setOpenButton(lists.pc_eid);
                            }}
                          >
                            <BiDotsVerticalRounded />
                          </Options>
                        )}
                      </FlexJustCenter>
                      {cardOpenId === lists.pc_eid && (
                        <Div1 ref={inputRef} display={open} right="-20px">
                          <Button
                            color="#414141"
                            display="block"
                            family="'Inter'"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            padding="8px 52px 8px 16px"
                            background="#ffffff"
                            textAlign="left"
                            width="100%"
                            hoverBackColor="#FAFAFA"
                            disabled={lists.accept === "2"}
                            marginTop="12px"
                            onClick={() => StartEditAppointment(lists)}
                          >
                            <RiEditLine marginRight="14px" />
                            <Span marginLeft="14px">Edit</Span>
                          </Button>
                          <Button
                            color="#414141"
                            display="flex"
                            family="'Inter'"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            padding="8px 52px 8px 16px"
                            background="#ffffff"
                            hoverBackColor="#FAFAFA"
                            width="100%"
                            disabled={
                              appointmentData["eid"] === lists.pc_eid ||
                              lists.accept === "2"
                            }
                            onClick={() => {
                              setCancelAppt(true);
                              dispatch(
                                changeAppointmentData({
                                  name: "cancelID",
                                  value: lists.pc_eid,
                                })
                              );
                              setOpen(false);
                            }}
                          >
                            <RiDeleteBin7Line marginRight="14px" />
                            <Span marginLeft="14px">Cancel</Span>
                          </Button>
                        </Div1>
                      )}
                      <CardBody index={index} accept={lists.accept}>
                        <List>
                          <BackgroundDiv index={index} accept={lists.accept}>
                            <BiUserCircle />
                          </BackgroundDiv>
                          {lists.provider_name}
                        </List>
                        <List display="flex">
                          <BackgroundDiv index={index} accept={lists.accept}>
                            <AiFillClockCircle />
                          </BackgroundDiv>
                          <Ptag>{lists.duration}</Ptag>
                        </List>
                        <List>
                          <div>
                            {lists.tele_health === "1" ? (
                              <>
                                <BackgroundDiv
                                  index={index}
                                  accept={lists.accept}
                                >
                                  <BiLocationPlus />
                                </BackgroundDiv>
                                "Tele Health"
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: "17px",
                                  }}
                                >
                                  <BackgroundDiv
                                    index={index}
                                    accept={lists.accept}
                                  >
                                    <BiLocationPlus />
                                  </BackgroundDiv>
                                  <p
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      marginTop: "14px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    {lists.facility_name}
                                  </p>
                                </div>
                                <Address index={index} accept={lists.accept}>
                                  {lists.street},{lists.city},{lists.state},
                                  {lists.postal_code}
                                </Address>
                              </>
                            )}
                          </div>
                        </List>
                      </CardBody>

                      {lists.accept === "0" && (
                        <Status accept={lists.accept}>
                          Appointment request{" "}
                        </Status>
                      )}

                      {lists.accept === "1" &&
                        lists.tele_health === "1" &&
                        index === 0 && (
                          <CheckinButton
                            index={index}
                            onClick={() =>
                              StartVideo(lists.eventDateTime, lists.start_url)
                            }
                          >
                            <BackgroundDiv index={index} tag="button">
                              <HiOutlineVideoCamera />
                            </BackgroundDiv>
                            <Span marginLeft="8px">Join Telehealth</Span>
                          </CheckinButton>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "1" &&
                        index > 0 && (
                          <Status accept={lists.accept}>
                            Join Telehealth{" "}
                          </Status>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "0" &&
                        index === 0 && (
                          <CheckinButton index={index}>
                            Appointment Confirmed
                          </CheckinButton>
                        )}

                      {lists.accept === "1" &&
                        lists.tele_health === "0" &&
                        index >= 1 && (
                          <Status accept={lists.accept}>
                            Appointment Confirmed{" "}
                          </Status>
                        )}

                      {lists.accept === "2" && (
                        <Status accept={lists.accept}>Cancelled </Status>
                      )}
                    </CardContainer>
                  ))}
                </>
              )}
            </Container>
          )}
        </>
      )}

      {cancelAppt && (
        <AppointmentCancel
          show={cancelAppt}
          HidePopup={() => {
            setCancelAppt(false);
          }}
        />
      )}
    </RespDiv>
  );
};

export default AppointmentsList;
