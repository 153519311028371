import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDocumentslist } from "../../StateManagement/Reducers/DocumentsState";

import TopNav from "../TopNav";
import DocumentList from "./DocumentList";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";
import { GetNotificationlabempty } from "../../StateManagement/Reducers/TopNavbarState";

const Document = () => {
  const dispatch = useDispatch();

  const [documenthead, setdocumenthead] = useState("Documents");

  const Loading_ = useSelector((state) => state.Document.Loading);
  const Refresh = useSelector((state) => state.Document.Refresh);
  const labnotify = useSelector((state) => state.TopNavbar.Notificationlab);
  useEffect(() => {
    dispatch(getDocumentslist({ filtertype: "All Documents" }));
  }, [dispatch, Refresh]);
  useEffect(() => {
    if (labnotify.length > 0) {
      setdocumenthead("Lab Results");
      dispatch(getDocumentslist({ filtertype: "Lab Results" }));
      dispatch(GetNotificationlabempty(""));
    }
  }, [labnotify]);

  return (
    <>
      <TopNav title="Document" />
      {Loading_ ? (
        <Loading />
      ) : (
        <BodyContainer
          className="mobRemovePad"
          paddingLeft="40px"
          paddingTop="4px"
        >
          <DocumentList
            documenthead={documenthead}
            setdocumenthead={setdocumenthead}
          />
        </BodyContainer>
      )}
    </>
  );
};

export default Document;
