import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { FlexCenter, FlexBetween, PageTitle } from "../StyledComponents";

export const FixedTop = styled(FlexBetween)`
  padding-right: 4px;
  height: ${({ height }) => height && height};
  transition: box-shadow 0.2s ease-in-out;
  padding: 20px 32px 20px 40px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  will-change: transform;
  ${(p) =>
    p.DualColor &&
    `background: linear-gradient(to right, #fff 0px, #fff 37%, #f4f4f4 37%, #f4f4f4 100%);`}

  ${(p) =>
    p.topTitle &&
    p.topTitle === "Appointment List" &&
    `
          @media (max-width: 1024px) {
          padding : 20px;
          }
          `}
          
  ${(p) =>
    p.scrolled &&
    ` 
      background : #FFF;
      box-shadow: 2px 4px 4px rgb(0 0 0 / 30%);
      will-change: transform;
   
      `}
      min-width : ${(p) => p.minWidth && p.minWidth};
  &.topnav {
    min-width: 650px;
    @media screen and (min-width: 480px) and (max-width: 570px) {
      min-width: 480px;
      max-width: 570px;
    }
    @media screen and (min-width: 400px) and (max-width: 480px) {
      min-width: 400px;
      max-width: 480px;
    }
    @media screen and (min-width: 350px) and (max-width: 400px) {
      min-width: 350px;
      max-width: 400px;
    }
    @media screen and (min-width: 100px) and (max-width: 350px) {
      min-width: 100px;
      max-width: 350px;
    }
  }
`;

export const MessageContainer = styled(FlexCenter)`
  border-radius: 50%;
  cursor: pointer;
  background: ${(p) => (p.DualColor ? "#fff" : "#fafafa")};
  width: 40px;
  height: 40px;
  margin-right: 16px;
  position: relative;

  &:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ff4d4d;
  }

  &.disable_notify {
    cursor: not-allowed;
  }
`;

export const ProfileBox = styled.div`
  background: ${(p) => (p.DualColor ? "#fff" : "#fafafa")};
  border-radius: 90px;
  padding: ${(p) => (p.padding ? p.padding : "0px 16px 0px 8px")};
  display: flex;
  align-items: center;
  height: 40px;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #232934;
  position: relative;
  cursor: pointer;
  max-width: 200px;
  & img {
    margin-right: 8px;
  }
  & svg {
    margin-left: 12px;
    min-width: 1em;
  }
`;

export const RedNotify = styled.div`
  position: absolute;
  top: 5px;
  right: -1px;
  width: 8px;
  height: 8px;
  background: #ff4d4d;
  border-radius: 50%;
`;

export const TopNavPlacer = styled.div`
  display: flex;
  z-index: 1001;
  align-items: center;
`;

// export const DropdownContentNotifi = styled.div`
//   position: absolute;
//   top: ${(p) => p.top && p.top};
//   width: 320px;
//   height: 327px;
//   right: 2%;
//   background: white;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
//   border-radius: 8px;
//   animation: fadeIn 0.6s;
//   display: ${(p) => (p.display === "true" ? "block" : "none")};
//   overflow: hidden;

//   @keyframes fadeIn {
//     0% {
//       height: 0;
//     }
//     100% {
//       height: 327px;
//     }
//   }

//   @-moz-keyframes fadeIn {
//     0% {
//       height: 0;
//     }
//     100% {
//       height: 327px;
//     }
//   }

//   @-webkit-keyframes fadeIn {
//     0% {
//       height: 0;
//     }
//     100% {
//       height: 327px;
//     }
//   }

//   @-o-keyframes fadeIn {
//     0% {
//       height: 0;
//     }
//     100% {
//       height: 327px;
//     }
//   }

//   @-ms-keyframes fadeIn {
//     0% {
//       height: 0;
//     }
//     100% {
//       height: 327px;
//     }
//   }
// `;
export const DropdownContentNotifi = styled.div`
  position: absolute;
  top: ${(p) => p.top && p.top};
  width: 320px;
  height: 327px;
  right: 2%;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  animation: fadeIn 0.6s;
  display: ${(p) => (p.display === "true" ? "block" : "none")};
  overflow: hidden;
  z-index: 1000;

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 327px;
    }
  }

  @media (max-width: 790px) {
    padding-top: "10px";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    overflow-y: auto;
    animation: none;
    box-shadow: none;
  }
`;
export const DropdownContent = styled.div`
  position: absolute;
  top: 46px;
  width: ${(p) => (p.width ? p.width : "98%")};
  left: ${(p) => (p.left ? p.left : "1%")};
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  animation: fadeIn 0.3s;
  display: ${(p) => (p.display === "true" ? "block" : "none")};
  overflow: hidden;

  & div:first-of-type svg {
    color: #667085;
  }
  & div svg {
    margin-right: 8px;
  }
  & div:nth-of-type(2) svg {
    color: #ff4d4d;
  }

  @keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      height: 0;
    }
    100% {
      height: 83px;
    }
  }
`;

export const DropdownList = styled.div`
  padding: 10px;
  color: ${(p) => (p.red ? "#F65160" : "#414141")};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 400;
  transition-duration: 0.3s;
  &:hover {
    background: #ececec;
  }
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  display: flex;
  align-itme: center;
`;

export const PageTitleVariant = styled(PageTitle)`
  @media (min-width: 800px) and (max-width: 840px) {
    font-size: 26px !important;
  }

  @media (max-width: 1100px) {
    font-size: 30px;
  }
  @media (max-width: 950px) {
    font-size: 28px;
  }
`;
export const Header = styled.div`
  height: 43px; /* Adjust the height as per your requirements */
  border-bottom: 1px rgba(46, 46, 46, 0.25) solid;
  padding: 10px 12px 8px 15px;
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.juscon && p.juscon};
  background: white; /* Adjust the background color as per your requirements */
`;
export const Title = styled.h1`
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  color: black;
  font-size: 18px;
  word-wrap: break-word;
`;

export const TextWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
