import styled from "styled-components";

export const Label = styled.label`
  font-size: ${(p) => p.size && p.size};
  font-weight: ${(p) => p.weight && p.weight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;

export const Div = styled.div`
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => (p.display ? p.display : "block")};
  width: ${(p) => p.width && p.width};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  background-color: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  font: ${(p) => p.font && p.font};
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  margin-top: ${(p) => p.marginTop && p.marginTop};

  align-items: center;
  @media (max-width: 820px) {
    width: ${(p) => p.resWidth && p.resWidth};
    padding: ${(p) => p.respadding && p.respadding};
  }
`;

export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  background: #ffffff;
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "8px")};
  height: ${(p) => (p.height ? p.height : "48px")};
  padding: 0px 18px;
  font-size: 14px;
  line-height: 24px;
  &:focus {
    outline: none;
  }
`;
