import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const TitleBlock = styled.div`
  display: inline;
  align-items: center;
`;

export const Title = styled.h2`
  color: ${(p) => p.color};
  font-weight: 700 !important;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "32px !important")};
  display: ${(p) => (p.display ? p.display : "inline")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "44px")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;

export const SubTitle = styled.h4`
  font-weight: 400;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px !important")};
  line-height: 27px;
  align-items: center;
  color: #667085;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "16px !important")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding: ${(p) => p.padding && p.padding};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;
//my changes
export const ClientButton = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 16px;
  display: inline-block;
  width: ${(p) => (p.width ? p.width : "200px")};
  height: ${(p) => (p.height ? p.height : "200px")};
  background: #ffffff;
  border: 1px solid #d8e0f0;
  text-decoration: none;
  margin-right: ${(p) => p.marginright && p.marginright};
  &:hover {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border: ${(p) => (p.hoverborder ? p.hoverborder : "1px solid #3f8cff")};
  }
`;

export const Status = styled.h5`
  font-weight: 500;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "18px")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: 25px;
  align-items: center;
  color: #000000;
  margin-bottom: 9px;
  margin-top: 12px;
  text-align: center;
`;
export const GoogleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  div {
    border-radius: 16px !important;
  }
`;
