import styled from "styled-components";

import { FlexJustCenter, Text, PageTitle } from "../../StyledComponents";

export const Tablewhole = styled.div`
  background: #f4f4f4;
  padding-right: 0px;
  height: 100%;
  @media (min-width: 320px) and (max-width: 768px) {
    background-color: unset;
    padding: 8px;
  }
`;

export const PastTopTitle = styled(FlexJustCenter)`
  padding: 31px 32px 32px 24px;
  margin-bottom: ${(p) => p.mb && p.mb};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  @media (min-width: 320px) and (max-width: 768px) {
    padding: 8px 8px 16px 16px;
  }
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color && props.color};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  height: ${(p) => p.height && p.height};
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
`;

export const TableHeadCell = styled.th`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #8d98af;
  padding: ${(p) => (p.padding ? p.padding : "20px")};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
`;

export const TableBodyCell = styled.td`
  font-size: 14px;
  line-height: 16px !important;
  color: #000000;
  //padding: 16px 40px 16px 38px;
  padding: ${(p) => (p.padding ? p.padding : "16px 40px 16px 38px")};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  width: ${(p) => p.width && p.width};
  align-items: ${(p) => p.alignItems && p.alignItems};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const TableRow = styled.tr`
  text-align: left;
  background-color: ${(props) => props.background};
  &:last-child ${TableBodyCell} {
    border-bottom: none;
  }
  display: ${(p) => p.display && p.display};
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  align-items: ${(p) => p.alignItem && p.alignItem};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  grid-template-columns: ${(p) => p.tempColumns && p.tempColumns};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
    /* padding-right: 0px; */
  }

  &.hover_row:hover ${TableBodyCell} {
    padding-right: 0px !important;
  }
`;

export const ResText = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const RespTitle = styled(PageTitle)`
  @media (max-width: 1024px) {
    font-size: 18px !important;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 16px;
  }
`;

// @media (max-width: 700px) {
//   display: none; // Hide table on smaller screens
// }

export const CardView = styled.div`
  display: none; // Hide by default
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 4px 0px rgba(46, 46, 46, 0.1);
  border: 1px solid #f4f4f4;
  margin-bottom: 8px;
  padding: 8px;
  width: 100%;

  @media (max-width: 768px) {
    display: block; // Show card view on smaller screens
  }
`;

export const Cardrow1 = styled.div`
  height: 60%;
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
`;

export const Cardrow2 = styled.div`
  height: 40%;
`;

export const CardHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #464646;
`;

export const CardContent = styled.div`
  font-size: 14px;
  color: #212121;
  margin-top: 4px;
  margin-bottom: 8px;
  white-space: nowrap;
  &.facility_responsive {
    white-space: normal;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 14px;
  color: #20c9ac; // For Status color, can be customized based on status
`;
