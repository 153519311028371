const getFilePath = () => {
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;

  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (firstDot[0] === "client") {
    DBhost = "app";
  } else if (
    [
      "localhost",
      "test",
      "mumbai",
      "production",
      "ehr",
      "ehr1",
      "dev",
    ].includes(firstDot[0])
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  return `${backurl}/sites/${DBhost}/documentimg/`;
};

export default getFilePath;
