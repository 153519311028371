import styled from "styled-components";

export const Div = styled.div`
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin: ${(p) => p.margin && p.margin};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  width: ${(p) => p.width && p.width};

  @media (max-width: 480px) {
    margin-right: ${(p) => p.marginRight && p.marginRight};
    margin-bottom: 0px;
    &.billingTabParant {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    &.mobCardBut {
      display: flex;
      justify-content: space-between;
    }
    &.mobPayHisRight {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;
