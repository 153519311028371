import styled from "styled-components";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";

export const StyledImage = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  border-radius: ${(props) => props.borderRadius || "50%"};
`;

export const Container = styled.div`
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  margin-right: 40px;
  @media (max-width: 480px) {
    border: none;
    margin-right: 0px;
  }
`;

export const TabPanel = styled.div`
  height: 31px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #d5ddec;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 480px) {
    background: #c3d7db77;
    gap: 0px;
    border-radius: 100px;
    height: 44px;
    width: 100%;
    margin: 0px 0px;
    justify-content: space-between;
  }
`;

export const Tabs = styled.div`
  font-weight: ${(props) => (props.active ? "600" : "normal")};
  font-size: 14px;
  line-height: 16px;
  margin-left: 32px;
  margin-top: 2px;
  margin-bottom: 7px;
  color: #2e2c34;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;

  ${(p) => {
    if (p.Line === false) {
      return `
              &::after {
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: #407bff;
                        display: block;
                        margin-top: 11px;
                        transform: translateX(-135px);
                        transition: transform 1s;
                         }
               `;
    }

    if (p.Line === true) {
      return `
              &::after {
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: #407bff;
                        display: block;
                        margin-top: 11px;
                        transform: translateX(0px);
                        transition: transform 1s;
                         }
               `;
    }
  }}

  @media (max-width: 480px) {
    &::after {
      content: none;
    }
    background-color: ${(props) => (props.active ? "#245c66" : "transparent")};
    color: ${(props) => (props.active ? "white" : "#2e2c34")};
    width: 50%;
    text-align: center;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => (props.active ? "100px" : "0")};
    margin-left: 0px;
  }
`;

export const AvatarFrame = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #a8aebe;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  @media (max-width: 480px) {
    border-radius: 8px;
    height: 40px;
    width: 40px;
  }
`;

export const AvatarTxt = styled.span`
  font-size: 16px;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

export const TxtSpan = styled.span`
  color: ${(p) => (p.color ? p.color : "#000000")};
  font-size: 14px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
`;

export const PaymentStatus = styled(TxtSpan)`
  width: 41px;
  color: #20c9ac;
  background: rgba(32, 201, 172, 0.1);
  border-radius: 4px;
  padding: 2px 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 60px;
    background: #20824126;
    border-radius: 25px;
    &.mobPayHisFont {
      font-size: 12px;
      padding: 0;
      width: 48px;
      height: 18px;
    }
  }
`;

export const ModalDiag = styled(ModalDialog)`
  width: ${(p) => (p.width ? p.width : "100vw")};
  height: ${(p) => (p.height ? p.height : "100vh")};
  background: ${(p) => (p.background ? p.background : "#ffffff")};
  position: relative;
`;
export const PaidStatus = styled.div`
  width: 130px;
  border: 2px solid green;
  border-radius: 4px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  color: green;
  padding: 15px;
`;
