import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 700;
  line-height: 44px;
  align-items: center;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "36px")};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "14px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};

  color: #000000;
`;
export const SubTitle = styled.h4`
  font-weight: 400;
  line-height: 27px;
  align-items: center;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "32px")};
  color: #667085;
`;

export const FormGroupInline = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 1200px) {
    display: unset;
  }
`;
