import styled from "styled-components";

export const Div = styled.div`
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const SubHeading = styled.h6`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #7d8592;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 19px;
    font-weight: 600;
    color: #000;
  }
`;

export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 8px;
  svg {
    font-size: 12px;
    margin-left: 7px;
  }
  @media (max-width: 480px) {
    margin-top: 4px;
  }
`;

export const DropDownListContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 179px;
  position: absolute;
  z-index: 100;
`;

export const DropDownList = styled.ul``;

export const ListItem = styled.li`
  list-style: none;
  font-size: 14px;
  line-height: 16px;
  color: #414141;
  padding: 10px 16px;
  cursor: poniter;
  &:hover {
    background: #ececec;
    cursor: pointer;
  }
`;
export const TitleLabel = styled.label`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "Work Sans" + ",sans-serif"};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ fontHeight }) => (fontHeight ? fontHeight : "19px")};
  color: ${({ color }) => (color ? color : "#333333")};
  margin-left: ${({ margin }) => margin && "20px"};
  width: ${({ width }) => (width ? width : "80%")};
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "nowrap")};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "4px")};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap && overflowWrap};
  &.problem_label {
    width: 400px;
  }
  @media screen and (max-width: 1200px) {
    &.problem_label {
      width: 220px;
    }
  }
  @media screen and (max-width: 950px) {
    &.problem_label {
      width: 180px;
    }
  }
`;
