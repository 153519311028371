import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import EmptyData from "../../StyledComponents/EmptyData";
import Pagination from "../../StyledComponents/Pagination";
import hospitalPro from "./../../../Assets/images/hospitalPro.png";
import labPro from "./../../../Assets/images/labPro.png";
import mediPro from "./../../../Assets/images/mediPro.png";
import getFilePath from "../../../Config/path";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  FlexJustCenter,
  CardContent,
  CardView,
} from "../../StyledComponents";
import {
  Container,
  Avatar,
  AvatarLetter,
  AvatarImage,
  Div,
  AvatarFrame,
  TxtSpan,
} from "./styles";
import { TitleLabel } from "../../HealthProfile/styles";

const Lists = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const ProfileLists = useSelector((state) => state.HealthProfile.List);

  // let backurl = "";
  // let firstDot = window.location.hostname.split(".");
  // let DBhost;
  // if (firstDot[0] !== "localhost") {
  //   backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
  //     "oemr",
  //     firstDot[0]
  //   );
  // } else {
  //   backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  // }
  // if (firstDot[0] === "client") {
  //   DBhost = "app";
  // } else if (
  //   firstDot[0] === "localhost" ||
  //   firstDot[0] === "test" ||
  //   firstDot[0] === "mumbai" ||
  //   firstDot[0] === "production" ||
  //   firstDot[0] === "ehr" ||
  //   firstDot[0] === "ehr1" ||
  //   firstDot[0] === "dev"
  // ) {
  //   DBhost = "default";
  // } else {
  //   DBhost = firstDot[0];
  // }

  // const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";
  const File_URL = getFilePath();

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentList = ProfileLists.slice(indexOfFirstRow, indexOfLastRow);
  const howManyPages = Math.ceil(ProfileLists.length / rowsPerPage);
  return (
    <Container className="marLeft" minWidth="650px">
      <Table>
        <TableHead>
          <TableRow background="#F7F7F7">
            <TableHeadCell width="33.33%">{props.list}</TableHeadCell>
            <TableHeadCell width="33.33%">Date Diagnosed</TableHeadCell>
            <TableHeadCell width="33.33%">Provider</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentList.length !== 0 ? (
            <>
              {currentList.map((row, ind) => (
                <TableRow background="#FFFFF" key={ind}>
                  <TableBodyCell width="33.33%">
                    <FlexJustCenter>
                      <TitleLabel
                        fontFamily="'manrope',sans-serif"
                        fontSize="14px"
                        fontHeight="19.12px"
                        fontWeight="500"
                        color="#263446"
                        paddingTop="0px"
                        overflowWrap="break-word"
                        whiteSpace="unset"
                        className="problem_label"
                      >
                        {row.title}
                      </TitleLabel>
                    </FlexJustCenter>
                  </TableBodyCell>
                  <TableBodyCell width="33.33%">
                    {props.list === "Medication" ? (
                      <>
                        {" "}
                        {row.moddate
                          ? moment(row.moddate).format("MM/DD/YYYY")
                          : "-"}{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        {row.date
                          ? moment(row.date).format("MM/DD/YYYY")
                          : "-"}{" "}
                      </>
                    )}
                  </TableBodyCell>
                  <TableBodyCell width="33.33%" padding="16px 40px 16px 36px">
                    <Div
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {row.providerImage === null ? (
                        <Avatar>
                          <AvatarLetter>
                            {row.providerName.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <AvatarImage
                          src={File_URL + row.providerImage}
                          alt="image-failed"
                          loading="lazy"
                        />
                      )}

                      {row.providerName}
                    </Div>
                  </TableBodyCell>
                </TableRow>
              ))}
            </>
          ) : (
            <>
              <TableRow background="#FFFFF">
                <TableBodyCell colSpan={3} textAlign="center">
                  <EmptyData />
                </TableBodyCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      {/* Card View (Mobile) */}
      {currentList.map((row, ind) => (
        <CardView key={ind}>
          <CardContent>
            <Div display="flex" justifyContent="space-between">
              <Div display="flex">
                <Div display="flex" alignItems="center">
                  <AvatarFrame key={ind}>
                    <AvatarImage
                      src={
                        props.list === "Medication"
                          ? mediPro
                          : props.list === "Lab Orders"
                          ? labPro
                          : props.list === "Conditions" && hospitalPro
                      }
                      alt="image-failed"
                      loading="lazy"
                    />
                  </AvatarFrame>
                </Div>

                <Div>
                  <TxtSpan>{row.title}</TxtSpan>

                  <Div marginTop="5px">
                    <TxtSpan fontWeight="500" color="#747575">
                      {row.providerName}
                    </TxtSpan>
                  </Div>
                </Div>
              </Div>
              <Div>
                <TxtSpan color="#747575">
                  {props.list === "Medication" ? (
                    <>
                      {row.moddate
                        ? moment(row.moddate).format("MM/DD/YYYY")
                        : "-"}
                    </>
                  ) : (
                    <>
                      {row.date ? moment(row.date).format("MM/DD/YYYY") : "-"}
                    </>
                  )}
                </TxtSpan>
              </Div>
            </Div>
          </CardContent>
        </CardView>
      ))}
      {ProfileLists.length > 10 && (
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      )}
    </Container>
  );
};

export default Lists;
