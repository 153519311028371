import styled from "styled-components";
import visa from "../../../Assets/images/visa.png";
import master from "../../../Assets/images/master.png";
import discover from "../../../Assets/images/discover.png";
import diners from "../../../Assets/images/diners.png";
import amex from "../../../Assets/images/amex.png";

const backgroundUrl = (cardType) => {
  let cardType1 = cardType.replace(/ /g, "").toLowerCase();
  switch (cardType1) {
    case "visa":
      return `url(${visa})`;
      break;
    case "americanexpress":
      return `url(${amex})`;

      break;
    case "americanexpresscorporate":
      return `url(${amex})`;
      break;
    case "amex":
      return `url(${amex})`;
      break;
    case "mastercard":
      return `url(${master})`;

      break;
    case "mastercard2":
      return `url(${master})`;

      break;
    case "discover":
      break;
    case "disco2":
      return `url(${discover})`;

      break;
    case "disco3":
      return `url(${discover})`;
      break;
    case "disco4":
      return `url(${discover})`;
      break;
    case "dinersclub":
      return `url(${diners})`;
      break;
    case "diners":
      return `url(${diners})`;
      break;
    default:
      return "none";
  }
};
export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
  &.Addcard_container_responsive {
    @media (min-width: 320px) and (max-width: 768px) {
      width: 85%;
      // height: 70%;
    }
  }
`;

export const Input = styled.input`
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  padding: 12px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 12px !important;
  }
  &:focus {
    outline: none;
  }
`;

export const AdCardNum = styled(Input)`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7e7c7c !important;
  }
  &:hover {
    cursor: ${(props) => (props.cursor_1 ? props.cursor_1 : "")};
  }
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: top 5px right 15px;
  background-image: ${(props) => backgroundUrl(props.cardType)};
`;

export const font1 = styled.div`
  font-family: "inter", sans-serif;
`;

export const ModalBody = styled.div`
  height: max-content;
`;

export const AdNameSpn = styled.div`
  width: 123px;
  height: 24px;
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2d3748;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 8px;
`;

export const AdNameSpn1 = styled(AdNameSpn)`
  margin-top: 16px;
  margin-left: 3px;
`;
export const AdNameSpn2 = styled(AdNameSpn1)`
  margin-left: -27px;
`;
export const AdNameSpn3 = styled(AdNameSpn1)`
  margin-left: -9px;
`;
export const AdNameSpn4 = styled(AdNameSpn1)`
  margin-left: 20px;
  margin-top: 20px;
  width: 30px;
`;
export const Default = styled.div`
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2d3748;
  margin-left: 8px;
  margin-top: -6px;
`;

export const CardProps = styled.div`
  display: flex;
`;

export const CardVal = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardVal1 = styled.div`
  display: flex;
  margin: 16px 0px 16px 20px;
`;

export const AdrsInput = styled(Input)`
  margin-bottom: 10px;
  width: 220px;
`;

export const Modclosebtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  padding-bottom: 1px;
  line-height: 1.15px;
  margin-right: 20px;
`;

export const ModalHead = styled.div`
  width: 512px;
  height: 71px;
  margin: 0px;
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
  // margin-left: 20px;
`;

export const ModalTitle = styled(font1)`
  margin: 20px;
  font-weight: 500;
  font-family: "inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  color: #000000;
`;

export const ModalFooter = styled.div`
  width: 512px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cbd5e0;
`;

export const Button = styled.button`
  padding: ${(props) => props.padding && props.padding};
  font-size: 14px;
  line-height: 17px;
  height: 34px;
  background-color: ${(props) => (props.selected ? " #2C7BE5" : "#FFFFFF")};
  border-radius: 4px;
  color: ${(props) => (props.selected ? " #FFFFFF" : "#404040")};
  font-weight: ${(p) => p.weight && p.weight};
  padding: ${(p) => p.pad && p.pad};
  text-align: center;
  margin-right: 20px;
  margin-left: ${(p) => p.ml && p.ml};
  outline: none;
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  border: ${(props) =>
    props.selected ? "0.5px solid #2C7BE5" : "0.5px solid #404040"};
`;

export const RButton = styled(Button)`
  width: 121px;
  background-color: rgba(239, 95, 95, 0.2);
  border: 0.5px solid #ef5f5f;
  margin-left: 20px;
  color: #ef5f5f;
  display: ${(props) => (props.selected ? "flex" : "none")};
  align-items: center;
  padding-left: 15px;
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
`;

export const ButtonGroup = styled.div`
  height: max-content;
  width: max-content;
  margin-left: auto;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 16px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const TogggleCheck = styled.input`
  cursor: ${(props) => (props.cursor_2 ? "not-allowed" : "pointer")};
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 26px;
  height: 16px;
  position: relative;
  left: 4px;
  &:checked + ${CheckBoxLabel} {
    background: #2c7be5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 14px;
      transition: 0.2s margin-left;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CardWrapper = styled.div`
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  width: ${(p) => p.width && p.width};
  ${(p) =>
    p.cardbrand &&
    `
      background-image: ${backgroundUrl(p.cardbrand)};
      background-size: 32px 32px;
      background-repeat: no-repeat;
      background-position: top 8px right 15px;
    `}
  &.addcard_cardno_responsive {
    @media (min-width: 320px) and (max-width: 768px) {
      //  width:0px;
    }
  }
`;
