import React, { useState, useEffect } from "react";
import { Modal, ModalContainer, Div, Text } from "../../StyledComponents";
// import { BsCheck, IoClose, RiError } from "./styles";
import { AiFillExclamationCircle } from "react-icons/ai";

const StripeAlert = (props) => {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [showAlert, setShowAlert] = useState(props.show);

  useEffect(() => {
    setShowAlert(props.show);
  }, [props.show]);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return (
    <>
      {showAlert && (
        <Modal
          show="flex"
          justifyContent="center"
          style={{
            width: screenSize.width * 1.0,
            height: screenSize.height * 1.0,
          }}
          onClick={props.handleModalClick}
        >
          <ModalContainer
            justifyContent="center"
            alignItems="center"
            top="40%"
            borderRadius="8px"
            backgroundColor={"#fffff"}
            border="1px solid transparent"
            borderColor={"#fffff"}
          >
            <Div
              width={props.maxWidth || ""}
              padding={"16px"}
              display="block"
              //   alignItems="center"
              backgroundColor={"#fffff"}
              borderColor={"#fffff"}
              borderRadius="8px"
            >
              <Div
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <AiFillExclamationCircle
                  style={{ fontSize: "25px", color: "#F24822" }}
                />

                {props.stripeFail ? (
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="normal"
                    color={"#2E2E2E"}
                    padding="0px 6px 0px 5px"
                    MaxWidth="373px"
                    align="left"
                  >
                    Payment Error
                  </Text>
                ) : (
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="normal"
                    color={"#2E2E2E"}
                    padding="0px 6px 0px 5px"
                    MaxWidth="373px"
                    align="left"
                  >
                    Can't Proceed with payment
                  </Text>
                )}
              </Div>
              {props.stripeFail ? (
                <>
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="normal"
                    color={"#2E2E2E"}
                    padding="8px 6px 14px 0px"
                    MaxWidth="373px"
                    align="left"
                  >
                    Can’t proceed the payment as the clients card is not
                    connected to the providers stripe key to make payment as
                    there is change in key details.
                  </Text>
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="normal"
                    color={"#2E2E2E"}
                    padding="8px 6px 4px 0px"
                    MaxWidth="373px"
                    align="left"
                  >
                    To proceed with payment remove the clients card and add it
                    again to secure a connection with stripe key
                  </Text>
                </>
              ) : (
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="normal"
                  color={"#2E2E2E"}
                  padding="8px 6px 4px 0px"
                  MaxWidth="373px"
                  align="left"
                >
                  Provider Account is not linked with stripe.Contact the
                  provider for further details & proceed with payment after the
                  account is linked
                </Text>
              )}
            </Div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default StripeAlert;
