import React from "react";
import { useLocation } from "react-router-dom";
import { BsXLg } from "react-icons/bs";

import { Navicon, StyledLink, Nav, Ul, Li } from "./Styles";
import { Div } from "../StyledComponents";

const LeftMobNav = ({ setNavopen }) => {
  const { pathname } = useLocation();

  const handleNavclose = () => {
    setNavopen(false);
  };
  return (
    <>
      <Nav padding="0px 12px 0px 24px">
        <Ul>
          <Li padding="10px 0px 10px 0px">
            <Div onClick={handleNavclose}>
              <BsXLg
                style={{
                  color: "#718096",
                  cursor: "pointer",
                  height: "30px",
                  width: "24px",
                  marginLeft: "16px",
                }}
                onClick={() => handleNavclose()}
                id="bil_inv-lst_cls"
              />
            </Div>
          </Li>
          <Li padding="10px 0px 10px 0px">
            {pathname === "/dashboard" ? (
              <StyledLink
                to="/dashboard"
                exact="true"
                onClick={handleNavclose}
                fontSize="25px"
                padding="20px 0px 20px 20px !important"
              >
                <Navicon id="dashboard_img" />
                <Navicon>Dashboard</Navicon>
              </StyledLink>
            ) : (
              <StyledLink
                to="/"
                exact="true"
                onClick={handleNavclose}
                fontSize="25px"
                padding="20px 0px 20px 20px !important"
              >
                <Navicon id="dashboard_img" />
                <Navicon id="nav_lnk-spn_dbrd">Dashboard</Navicon>
              </StyledLink>
            )}
          </Li>
          <Li padding="10px 0px 10px 0px">
            <StyledLink
              to="/appointment/past_appointment"
              className={pathname.includes("/appointment/") && "active"}
              exact="true"
              onClick={handleNavclose}
              fontSize="25px"
              padding="20px 0px 20px 20px !important"
            >
              <Navicon id="appointment_img" />
              <Navicon>Appointment</Navicon>
            </StyledLink>
          </Li>
          <Li padding="10px 0px 10px 0px">
            <StyledLink
              to="/billing"
              exact="true"
              onClick={handleNavclose}
              fontSize="25px"
              padding="20px 0px 20px 20px !important"
            >
              <Navicon id="billing_img" />
              <Navicon id="nav_lnk-spn_bil">Billing</Navicon>
            </StyledLink>
          </Li>

          <Li padding="10px 0px 10px 0px">
            <StyledLink
              to="/documents"
              exact="true"
              onClick={handleNavclose}
              fontSize="25px"
              padding="20px 0px 20px 20px !important"
            >
              <Navicon id="document_img" />
              <Navicon>Documents</Navicon>
            </StyledLink>
          </Li>
          <Li padding="10px 0px 10px 0px">
            <StyledLink
              to="/HealthProfile"
              exact="true"
              onClick={handleNavclose}
              fontSize="25px"
              padding="20px 0px 20px 20px !important"
            >
              <Navicon id="healthprofile_img" />
              <Navicon id="nav_lnk-spn_hltpr">HealthProfile</Navicon>
            </StyledLink>
          </Li>

          <Li padding="10px 0px 10px 0px">
            <StyledLink
              to="/settings"
              exact="true"
              onClick={handleNavclose}
              fontSize="25px"
              padding="20px 0px 20px 20px !important"
            >
              <Navicon id="settings_img" />
              <Navicon>Settings</Navicon>
            </StyledLink>
          </Li>
        </Ul>
      </Nav>
    </>
  );
};

export default LeftMobNav;
